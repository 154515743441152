@import url(https://use.typekit.net/cxp5wyv.css);
:root {
  --PRIMARY_BLUE: #4691FF;
}

@font-face {
  font-family: "Myriad-Pro Light";
  font-weight: normal;
  src: url(/static/media/MyriadPro-Light.1d169f60.otf) format("opentype");
}

@font-face {
  font-family: "Myriad-Pro Regular";
  font-weight: inherit;
  src: url(/static/media/MYRIADPRO-REGULAR.6c518fcd.OTF) format("opentype");
}

@font-face {
  font-family: "Myriad-Pro Bold";
  font-weight: inherit;
  src: url(/static/media/MYRIADPRO-BOLDCOND.3b131879.OTF) format("opentype");
}

* {
  font-family: "Myriad-Pro Light", "Myriad-Pro Regular", sans-serif;
}

body {
  margin: 0;
  box-sizing: border-box;
  background-color: #fff !important;
  height: 100dvh;
}

.accountDashboardLink {
  color: #000;
}

.accountDashboardLink:hover {
  color: #000;
  text-decoration: none;
}

.App {
  text-align: center;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.App-link {
  color: #61dafb;
}

.makeStyles-formControl-82 {
  margin: 0 !important;
}

.font-14 {
  font-size: 14px;
}

.font-25 {
  font-size: 25px;
}

.facilityIcon {
  color: #E5C000;
}

.loadsIcon {
  color: #4691FF
}

.display-none {
  display: none !important;
}

.form_bidding .form-group {
  margin-bottom: 0;
}

.dollarInput {
  position: relative;
}

.dollarInput input {
  text-align: center;
}

.dollarSign {
  position: absolute;
  content: '$';
  left: 36%;
  z-index: 1;
  display: block;
  padding: 0 8px;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rotateIcon {
  animation: rotating 0.5s linear infinite;
}

.invoiceServiceWrapperGridToPrint {
  display: none;
}

.alertbase {
  font-weight: 600;
}

@media print {

  .addServicesInvoice,
  .printInvoice,
  /* .invoiceServiceWrapperGrid, */
  .stack_Uploaders {
    display: none !important;
  }

  .serviceInputField {
    border: none;
  }

  .invoiceServiceWrapperGridToPrint {
    display: inline !important;
  }
}


label {
  margin-bottom: 0 !important;
}

@page {
  size: 21cm 29.7cm;
  margin: 2cm
}

.notesPrintBlock{
  display: none;
}

@media print {

  .printArea h1 {
    page-break-after: always;
    page-break-before: always;
  }

  .bucketImageContainer {
    float: none!important;
  }

  .notesStack, .addNewItem, .deleteService{
    display: none;
  }

  .notesPrintBlock{
    display: block;
  }

  .bucketImageContainer > div {
    display: block;
    page-break-after: always!important;
    page-break-before: always!important;
  }

  .printThisFull {
    width: 96%;
    height: 96%;
    object-fit: contain;
  }
}



